
































































































































































import { Component, Vue } from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView.vue";
import api from "@/api";
import { ProductRevisePriceRecordDto, ProductSkuDto } from "@/api/appService";

@Component({
  name: "pricingManagementDetail",
  components: {
    PagedTableView,
  },
})
export default class pricingList extends Vue {
  queryForm = {
    customerCode: undefined,
    realName: undefined,
  };
  pricingId = 0;
  isShow = false;
  isCreate = true;
  productSkuId = 0;
  detail: ProductSkuDto = {};
  form: ProductRevisePriceRecordDto = {
    id: 0,
    originalFactoryPrice: undefined,
    revisedFactoryPrice: undefined,
    remark: "",
    productSkuId: undefined,
    customerId: 0,
  };

  // 获取表数据
  created() {
    this.productSkuId = Number(this.$route.params.id);
    this.fetchData(this.queryForm);
    this.fetchDetail();
  }

  jumpLogList() {
    this.$router.push({
      name: "adjustLogList",
      params: { id: this.$route.params.id!.toString() },
    });
  }

  // 获取详情
  async fetchDetail() {
    await api.productSku.get({ id: this.productSkuId }).then((res) => {
      this.detail = { ...res };
    });
  }

  fetchData(params: any) {
    params.productSkuId = this.productSkuId;
    return api.productRevisePriceRecord.getMainAll(params);
  }

  // 新建
  handleCreate(index: number, row: ProductRevisePriceRecordDto) {
    this.form = {} as ProductRevisePriceRecordDto;
    this.form.customerId = row.customerId;
    console.log(row);
    this.form.productSkuId = Number(this.$route.params.id);
    this.isCreate = true;
    this.isShow = true;
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await api.productRevisePriceRecord.create({ body: { ...this.form } });
        this.isShow = false;
        this.$message.success(
          (this as any).$l.getLanguageText(
            "pricingManagement.createSuccess"
          ) as string
        );
      }
    });
  }

  roleRule = {
    revisedFactoryPrice: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "pricingManagement.modifiedPrice"
        ) as string,
        trigger: "blur",
      },
    ],
  };
}
